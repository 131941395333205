<template>
  <CRow v-if="permissionsData.view_users">
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CRow>
            <CCol class="card-header-with-button">Users</CCol>
            <CCol class="text-right"
              ><CButton color="success" @click="addUserClick"
                >Add User</CButton
              ></CCol
            >
          </CRow>
        </CCardHeader>
        <CCardBody>
          <div v-if="showTable">
            <CDataTable
              hover
              striped
              :items="userData"
              :fields="fields"
              :items-per-page="15"
              clickable-rows
              :active-page="activePage"
              @row-clicked="rowClicked"
              :pagination="{ doubleArrows: false, align: 'center' }"
              @page-change="pageChange"
            >
              <template #status="data">
                <td>
                  <CBadge :color="getBadge(data.item.status)">
                    {{ data.item.status }}
                  </CBadge>
                </td>
              </template>
            </CDataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from "../../store";
import constants from "../../data/Constants.js";
import DynamoDBService from "../../services/dynamodbservice.js";
export default {
  name: "Users",
  data() {
    return {
      permissionsData:{},
      credentials: null,
      showTable: false,
      userData: [],
      fields: [
        { key: "name", _classes: "font-weight-bold" },
        { key: "email" },
        { key: "phone_number" },
        { key: "group" },
        { key: "status" },
      ],
      activePage: 1,
    };
  },
  mounted: function() {
         this.permissionsData = store.state.permissions;
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  created() {
    if (this.credentials) {
      // have credentials
      console.log("in created(), have credentials");
      this.getUsersItems();
    } else {
      // no credentials
      console.log("in created(), getting credentials...");
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getCredentials().then(
        (data) => {
          this.credentials = data;
          this.getUsersItems();
        },
        (error) => {
          console.log(error);
          this.getUsersItems();
        }
      );
    }
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "danger";
        default:
          "primary";
      }
    },
    rowClicked(item, index) {
      this.$router.push({ path: `users/edit`, query: { selectedData: item } });
    },
    addUserClick() {
      this.$router.push({ path: `users/0` });
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    getUsersItems() {
      var that = this;
      var params = {
        TableName: constants.USERS_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.userData = [];
            data.Items.forEach((element) => {
              var temp = {};
              temp.name = element.name;
              if(element.name == undefined){
                temp.name = ""
              }
              temp.email = element.userid;
              temp.group = element.jogg_role;

              temp.status = element.status;
              temp.sites = element.sites;
              temp.phone_number = element.phone_number;
              if(element.phone_number == undefined){
                temp.phone_number = ""
              }
              that.userData.push(temp);
            });
              that.showTable = false;
              that.$nextTick().then(function() {
                that.showTable = true;
              });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
